import React from "react";

const CookItPrivacyPage = () => {
  return (
    <div style={{ padding: 20, fontFamily: "'Montserrat', sans-serif" }}>
      <h2>Privacy Policy</h2>
      <p>
        Nerina Serra built the "Cook it" app as a free app. 
        This SERVICE is provided by Nerina Serra at no cost and is intended for use as is.
      </p>
      <p>
        This page is used to inform website visitors regarding "Cook it" policies with the collection, 
        use, and disclosure of Personal Information if anyone decided to use "Cook it" Service.
      </p>
      <p>
        If you choose to use "Cook it" Service, then you agree to the collection and use of information in 
        relation with this policy. The Personal Information that "Cook it" collect are used for providing and 
        improving the Service. "Cook it" will not use or share your information with anyone except as described 
        in this Privacy Policy.
      </p>

      {
        // <p>
        //   The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Superlista, 
        //   unless otherwise defined in this Privacy Policy.
        // </p>
      }

      <p><strong>Information Collection and Use</strong></p>
      <p>
        We allow you to register through Facebook and Google, therefore, we only get the social media profile 
        information. Like your email, full name and profile picture.
      </p>
      <p>
        We collect content and other data that you provide to us when you use our app, for example when you create 
        or share recipes.
      </p>
      <p>
        We share this data with other users only if you explicitly decide to mark the recipes you have created 
        as public.
      </p>
      <p>
        When you mark a recipe as public, other users will be able to view and add it to their list of 
        favorite recipes.
      </p>

      <p><strong>Log Data</strong></p>
      <p>
        "Cook it" want to inform you that whenever you use "Cook it" Service, in case of an error in the app 
        "Cook it" collect data and information (through third party products) on your phone called Log Data. 
        This Log Data may include information such as your devices’s Internet Protocol (“IP”) address, 
        device name, operating system version, configuration of the app when utilising "Cook it" Service, 
        the time and date of your use of the Service, and other statistics.
      </p>

      <p><strong>Cookies</strong></p>
      <p>
        Cookies are files with small amount of data that is commonly used an anonymous unique identifier. 
        These are sent to your browser from the website that you visit and are stored on your devices’s 
        internal memory.
      </p>
      <p>
        This Services does not uses these “cookies” explicitly. However, the app may use third party code and 
        libraries that use “cookies” to collection information and to improve their services. You have the option 
        to either accept or refuse these cookies, and know when a cookie is being sent to your device.
      </p>

      <p><strong>Security</strong></p>
      <p>
        The information you share with us is stored on servers that use secure SSL connections. 
        We use the services of Firebase, Google application. For more information on Firebase's privacy 
        policies go to <a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a>
      </p>

      <p><strong>Changes to This Privacy Policy</strong></p>
      <p>
        "Cook it" may update our Privacy Policy from time to time. Thus, you are advised to review this page 
        periodically for any changes. "Cook it" will notify you of any changes by posting the new Privacy Policy 
        on this page. These changes are effective immediately, after they are posted on this page.
      </p>

      <p><strong>Contact Us</strong></p>
      <p>
        If you have any questions or suggestions about "Cook it" Privacy Policy, do not hesitate to contact us.
      </p>
    </div>
  );
};

export default CookItPrivacyPage;